import { getSrcset } from "@/utils/getSrcset";
import type { BoxProps } from "@chakra-ui/react";
import { Box, chakra, useBreakpointValue } from "@chakra-ui/react";
import { motion, useAnimation } from "framer-motion";
import * as React from "react";

const animationVariants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

export interface FadeInImageProps extends Omit<BoxProps, "as"> {
  src: any;
  alt: string;
  sizes?: string | { [key: string]: string };
}

const FadeInImage = React.forwardRef<HTMLDivElement, FadeInImageProps>(
  ({ src, alt, children, sizes, ...rest }, ref) => {
    const [loaded, setLoaded] = React.useState(false);
    const animationControls = useAnimation();
    const breakpointSize = useBreakpointValue(typeof sizes === "object" ? sizes : {});

    React.useEffect(() => {
      if (loaded) {
        animationControls.start("visible");
      }
    }, [animationControls, loaded]);

    if (sizes && typeof sizes === "object" && !breakpointSize) {
      return <></>;
    }

    return (
      <Box position="relative" borderRadius="lg" overflow="hidden" bg="gray.100" ref={ref} {...rest}>
        <motion.div
          initial="hidden"
          animate={animationControls}
          variants={animationVariants}
          transition={{ ease: "easeOut", duration: 1 }}
          style={{ height: "100%" }}
        >
          <chakra.img
            objectFit="cover"
            height="100%"
            width="100%"
            alt={alt}
            srcSet={getSrcset(src)}
            sizes={breakpointSize ?? (sizes as string)}
            onLoad={() => setLoaded(true)}
            loading="lazy"
            src={src}
          />
        </motion.div>
        {children}
      </Box>
    );
  },
);

FadeInImage.displayName = "FadeInImage";

export { FadeInImage };
