export const DISCOUNT: Record<
  string,
  {
    discount: number;
    footnote?: string;
    apartmentSlugs?: Record<
      string,
      {
        discount: number;
        footnote?: string;
      }
    >;
    apartmentTypes?: Record<
      string,
      {
        discount: number;
        footnote?: string;
      }
    >;
  }
> = {
  hamburg: {
    discount: 20,
    footnote: "discount-footnote",
    apartmentSlugs: undefined,
  },
  paris: {
    discount: 20,
    footnote: "discount-footnote",
    // apartmentSlugs: {
    //   victor: {
    //     discount: 20,
    //     footnote: "discount-footnote",
    //   },
    // },
    apartmentTypes: undefined,
  },
  berlin: {
    discount: 20,
    footnote: "discount-footnote",
    // apartmentSlugs: {
    //   "rudolf-15": {
    //     discount: 20,
    //     footnote: "discount-footnote-rudolf",
    //   },
    //   "rudolf-20": {
    //     discount: 20,
    //     footnote: "discount-footnote-rudolf",
    //   },
    //   "rudolf-27": {
    //     discount: 20,
    //     footnote: "discount-footnote-rudolf",
    //   },
    //   "rudolf-33": {
    //     discount: 20,
    //     footnote: "discount-footnote-rudolf",
    //   },
    //   "rudolf-34": {
    //     discount: 20,
    //     footnote: "discount-footnote-rudolf",
    //   },
    //   "rudolf-30": {
    //     discount: 20,
    //     footnote: "discount-footnote-rudolf",
    //   },
    //   "flora-43": {
    //     discount: 20,
    //     footnote: "discount-footnote-flora",
    //   },
    //   "invalidenstr-34": {
    //     discount: 20,
    //     footnote: "discount-footnote-flora",
    //   },
    //   "flora-06": {
    //     discount: 20,
    //     footnote: "discount-footnote-flora",
    //   },
    //   "flora-05": {
    //     discount: 20,
    //     footnote: "discount-footnote-flora",
    //   },
    //   "flora-16": {
    //     discount: 20,
    //     footnote: "discount-footnote-flora",
    //   },
    //   "flora-25": {
    //     discount: 20,
    //     footnote: "discount-footnote-flora",
    //   },
    //   "theodor-1": {
    //     discount: 20,
    //     footnote: "discount-footnote-theodor",
    //   },
    //   "theodor-2": {
    //     discount: 20,
    //     footnote: "discount-footnote-theodor",
    //   },
    //   "theodor-3": {
    //     discount: 20,
    //     footnote: "discount-footnote-theodor",
    //   },
    //   "theodor-4": {
    //     discount: 20,
    //     footnote: "discount-footnote-theodor",
    //   },
    //   "theodor-5": {
    //     discount: 20,
    //     footnote: "discount-footnote-theodor",
    //   },
    //   "theodor-7": {
    //     discount: 20,
    //     footnote: "discount-footnote-theodor",
    //   },
    //   "arnold-1": {
    //     discount: 20,
    //     footnote: "discount-footnote-arnold",
    //   },
    //   "arnold-2": {
    //     discount: 20,
    //     footnote: "discount-footnote-arnold",
    //   },
    //   "arnold-3": {
    //     discount: 20,
    //     footnote: "discount-footnote-arnold",
    //   },
    //   "arnold-4": {
    //     discount: 20,
    //     footnote: "discount-footnote-arnold",
    //   },
    //   "arnold-5": {
    //     discount: 20,
    //     footnote: "discount-footnote-arnold",
    //   },
    //   "arnold-6": {
    //     discount: 20,
    //     footnote: "discount-footnote-arnold",
    //   },
    //   "arnold-7": {
    //     discount: 20,
    //     footnote: "discount-footnote-arnold",
    //   },
    //   "arnold-8": {
    //     discount: 20,
    //     footnote: "discount-footnote-arnold",
    //   },
    //   "arnold-9": {
    //     discount: 20,
    //     footnote: "discount-footnote-arnold",
    //   },
    //   "arnold-10": {
    //     discount: 20,
    //     footnote: "discount-footnote-arnold",
    //   },
    //   "arnold-11": {
    //     discount: 20,
    //     footnote: "discount-footnote-arnold",
    //   },
    //   "arnold-12": {
    //     discount: 20,
    //     footnote: "discount-footnote-arnold",
    //   },
    //   "arnold-13": {
    //     discount: 20,
    //     footnote: "discount-footnote-arnold",
    //   },
    //   "arnold-14": {
    //     discount: 20,
    //     footnote: "discount-footnote-arnold",
    //   },
    //   "arnold-15": {
    //     discount: 20,
    //     footnote: "discount-footnote-arnold",
    //   },
    //   "arnold-16": {
    //     discount: 20,
    //     footnote: "discount-footnote-arnold",
    //   },
    //   "arnold-17": {
    //     discount: 20,
    //     footnote: "discount-footnote-arnold",
    //   },
    //   "arnold-18": {
    //     discount: 20,
    //     footnote: "discount-footnote-arnold",
    //   },
    //   "arnold-19": {
    //     discount: 20,
    //     footnote: "discount-footnote-arnold",
    //   },
    //   "arnold-20": {
    //     discount: 20,
    //     footnote: "discount-footnote-arnold",
    //   },
    //   "arnold-21": {
    //     discount: 20,
    //     footnote: "discount-footnote-arnold",
    //   },
    //   "arnold-22": {
    //     discount: 20,
    //     footnote: "discount-footnote-arnold",
    //   },
    //   "arnold-23": {
    //     discount: 20,
    //     footnote: "discount-footnote-arnold",
    //   },
    //   "arnold-24": {
    //     discount: 20,
    //     footnote: "discount-footnote-arnold",
    //   },
    // },
    apartmentTypes: undefined,
  },
  oslo: {
    discount: 15,
    footnote: "discount-footnote",
    apartmentTypes: undefined,
  },
  copenhagen: {
    discount: 15,
    footnote: "discount-footnote-copenhagen",
    apartmentTypes: undefined,
  },
  aarhus: {
    discount: 15,
    footnote: "discount-footnote-aarhus",
    apartmentTypes: undefined,
  },
};

export const DEPOSIT: Record<string, { shared: number; private: number }> = {
  aarhus: {
    private: 10000,
    shared: 10000,
  },
  copenhagen: {
    private: 10000,
    shared: 10000,
  },
  oslo: {
    private: 0,
    shared: 0,
  },
  berlin: {
    private: 1000,
    shared: 1000,
  },
  halle: {
    private: 1000,
    shared: 1000,
  },
  hamburg: {
    private: 1800,
    shared: 1000,
  },
  munich: {
    private: 1000,
    shared: 1000,
  },
  paris: {
    private: 1000,
    shared: 1000,
  },
  london: {
    private: 1300,
    shared: 1300,
  },
};
