import { FadeInImage } from "@/components/FadeInImage";
import { ChevronLeft, ChevronRight } from "@/icons";
import type { BoxProps, IconButtonProps } from "@chakra-ui/react";
import { Box, Icon, IconButton } from "@chakra-ui/react";
import useEmblaCarousel from "embla-carousel-react";
import * as React from "react";
import { Pagination } from "./Pagination";

export const CarouselButton = (props: IconButtonProps) => (
  <IconButton
    borderRadius="50%"
    top="50%"
    transform="translateY(-50%)"
    display="grid"
    _hover={{ opacity: 1, transform: "translateY(-50%) scale(1.04)" }}
    size="sm"
    variant="tertiary"
    color="gray.900"
    willChange="opacity, transform"
    opacity={{ base: 1, md: 0 }}
    placeItems="center"
    sx={{ position: "absolute !important", zIndex: "2 !important" }}
    {...props}
  />
);

export interface CarouselProps extends BoxProps {
  images: { url: string; alt?: string }[];
  height: BoxProps["height"];
  width: BoxProps["width"];
  imageSizes?: string | { [key: string]: string };
  containerProps?: BoxProps;
}

export const Carousel = ({ children, containerProps, images, height, width, imageSizes, ...rest }: CarouselProps) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true });
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [slidesInView, setSlidesInView] = React.useState<number[]>([]);

  const findSlidesInView = React.useCallback(() => {
    if (!emblaApi) return;

    setSlidesInView((slidesInView) => {
      const totalSlides = emblaApi.slideNodes().length;
      if (slidesInView.length === totalSlides) {
        emblaApi.off("select", findSlidesInView);
      }
      const inView = emblaApi.slidesInView(true).filter((index) => !slidesInView.includes(index));
      if (inView.length === totalSlides) {
        // bug: on first render, all slides are somehow in view
        // this check fix it as we always show one slide at a time
        return slidesInView;
      }
      return [...slidesInView, ...inView];
    });
  }, [emblaApi, setSlidesInView]);

  const onSelect = React.useCallback(() => {
    if (!emblaApi) return;
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, [emblaApi, setSelectedIndex]);

  React.useEffect(() => {
    if (!emblaApi) return;
    onSelect();
    findSlidesInView();
    emblaApi.on("select", onSelect);
    emblaApi.on("select", findSlidesInView);
  }, [emblaApi, onSelect, findSlidesInView]);

  const scrollPrev = React.useCallback(() => {
    if (emblaApi) {
      emblaApi.scrollPrev();
    }
  }, [emblaApi]);

  const scrollNext = React.useCallback(() => {
    if (emblaApi) {
      emblaApi.scrollNext();
    }
  }, [emblaApi]);

  if (images.length === 1) {
    return (
      <Box position="relative" height={height} width={width} {...rest}>
        <Box overflow="hidden" borderRadius="lg" height={height} width={width} {...containerProps}>
          {children}
          <Box height="100%" display="flex">
            <Box position="relative" height="100%" flex="0 0 100%">
              <FadeInImage
                bg="gray.100"
                src={`${images[0].url}`}
                alt={images[0].alt ?? ""}
                height={height}
                sizes={imageSizes}
                width={width}
                borderRadius={0}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box position="relative" height={height} width={width} {...rest}>
      <Box overflow="hidden" borderRadius="lg" height={height} width={width} ref={emblaRef} {...containerProps}>
        {children}
        <Box height="100%" display="flex">
          {images.map((image, index) => (
            <Box position="relative" height="100%" flex="0 0 100%" key={`${image.url}-${index}`}>
              <FadeInImage
                bg="gray.100"
                src={slidesInView.includes(index) || images.length === 1 ? `${image.url}` : undefined}
                alt={image.alt ?? ""}
                height={height}
                sizes={imageSizes}
                width={width}
                borderRadius={0}
              />
            </Box>
          ))}
        </Box>
        <Pagination currentSlide={selectedIndex} totalSlides={images.length} />
      </Box>
      <CarouselButton
        onClick={scrollPrev}
        left={2}
        aria-label="previous image"
        icon={<Icon as={ChevronLeft} h={6} w={6} />}
        className="carousel-button"
      />
      <CarouselButton
        onClick={scrollNext}
        right={2}
        aria-label="next image"
        icon={<Icon as={ChevronRight} h={6} w={6} />}
        className="carousel-button"
      />
    </Box>
  );
};
