import { Box } from "@chakra-ui/react";
import * as React from "react";

interface PaginationDotProps {
  index: number;
  currentSlide: number;
  totalSlides: number;
}

export const PaginationDot = ({ index, currentSlide, totalSlides }: PaginationDotProps) => {
  const selected = index === currentSlide;
  const inNeighbourhood =
    Math.abs(currentSlide - index) === 1 ||
    (index === 2 && currentSlide === 0) ||
    (currentSlide === totalSlides - 1 && index === totalSlides - 3);

  return (
    <Box
      bg="white"
      borderRadius="50%"
      opacity={currentSlide === index ? 1 : 0.5}
      minH={inNeighbourhood || selected ? 2 : 1.5}
      minW={inNeighbourhood || selected ? 2 : 1.5}
    />
  );
};
