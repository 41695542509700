export const DEFAULT_CONTENTFUL_IMAGES_QUALITY = "?q=80&w=480&fm=webp";

export const assetsToImages = (
  assets: {
    file: {
      url?: string;
      details?: {
        image?: {
          height?: number;
          width?: number;
        };
      };
    };
    title?: string;
  }[],
): { baseUrl: string; url: string; alt: string; height?: number; width?: number }[] => {
  const images = assets.map((photo) => {
    return {
      url: `https:${photo.file?.url}${DEFAULT_CONTENTFUL_IMAGES_QUALITY}` as string,
      baseUrl: `https:${photo.file?.url}` as string,
      alt: photo.title ?? "",
      height: photo.file?.details?.image?.height,
      width: photo.file?.details?.image?.width,
    };
  });

  return images;
};
