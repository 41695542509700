import { Box, HStack } from "@chakra-ui/layout";
import * as React from "react";
import { PaginationDot } from "./PaginationDot";

const MAX_DOTS = 5;

interface PaginationProps {
  currentSlide: number;
  totalSlides: number;
}

export const Pagination = ({ currentSlide, totalSlides }: PaginationProps) => {
  return (
    <Box maxW="68px" position="absolute" bottom={2} left={0} right={0} margin="0 auto" overflow="hidden">
      <HStack
        spacing={2}
        transform={
          totalSlides < MAX_DOTS
            ? undefined
            : `translate(${Math.min(Math.max(currentSlide - 2, 0), totalSlides - MAX_DOTS) * -14}px)`
        }
        transition="-ms-transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s, -webkit-transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s, transform 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s"
      >
        {Array.from({ length: totalSlides }).map((_, index) => (
          <PaginationDot currentSlide={currentSlide} totalSlides={totalSlides} key={index} index={index} />
        ))}
      </HStack>
    </Box>
  );
};
